export const integratePendo = (value) => {
  // console.log("integratePendo => ", value);
  // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
  // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
  // Call this function after users are authenticated in your app and your visitor and account id values are available
  // Please use Strings, Numbers, or Bools for value types.
  window.pendo.initialize({
    visitor: {
      id: value.id, // Required if user is logged in, default creates anonymous ID
      email: value.email, // Recommended if using Pendo Feedback, or NPS Email
      role: value.user_role,
      full_name: value["name"] + " " + value["lastname"],
      platform_type: value.default_language === "en" ? "Global" : "French",
      created_at: formatDate(value.created_at),
      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      client_name: value.partner_name,
      id: value.invitation_code_id === 0 ? null : value.invitation_code_id,
      // id: 'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      /// Ajouter === id: client_id / name: client_name
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional
      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });
};

export const formatDate = (date) => {
  return date.split("T")[0];
};
