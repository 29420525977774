import AdvancedResultService from '../services/advanced_result.service';

const getDefaultState = () => {
    return {
        type : "",
        count : 0,
        data : {}, 
        select_export : {
            isSelect : false,
            listID : [],
            checkAll : false
        },
        column : [],
        default_column : [],
        reset_column : [],
        load : false, 
        load_excel : false,
        data_drug_centers: {},
        data_gbas: {},
        sort_column : {}

    }
}

const state = getDefaultState()

const getters = {
    type: state => state.type,
    count: state => state.count,
    data: state => state.data,    
    select_export: state => state.select_export, 
    column: state => state.column,   
    default_column: state => state.default_column, 
    reset_column: state => state.reset_column, 
    load: state => state.load,
    load_excel: state => state.load_excel,
    data_drug_centers: state => state.data_drug_centers,
    data_gbas: state => state.data_gbas,
    sort_column: state => state.sort_column
};

const actions = {     
    result_essais_clinique({ commit }, payLoad) { 
        commit('setLoad', true);
        return AdvancedResultService.result_essais_clinique(payLoad).then(
            res => { 
                commit('result', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },    
    result_essais_clinique_excel({ commit }, [payLoad, fileName]) { 
        commit('setLoadExcel', true);
        return AdvancedResultService.result_essais_clinique_excel(payLoad, fileName).then(
            res => { 
                commit('setLoadExcel', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadExcel', false);
                return Promise.reject(error);
            }
        )
    },
    saveColumn({ commit }, payLoad) { 
        commit('setLoad', true);
        return AdvancedResultService.save_column(payLoad).then(
            res => { 
                commit('setColumn', res);                
                commit('setLoad', false);   
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },    
    select_export({ commit }, val) {
        commit('select_export', val);
    },     
    clearState({ commit }){
        commit('clearState');
    },   
};
const mutations = {
    
     
    result(state, res) {  
        if(state.count === 0){ // set first only
            state.count = res.data.total; 
        }      
        state.data = res.data; 
        state.type = res.type;   
        state.column = res.column;  
        state.default_column = res.default_column; 
        state.reset_column = res.reset_column; 
        
        if(state.data_drug_centers)
            state.data_drug_centers = res.data_drug_centers

        if(state.data_gbas)
            state.data_gbas = res.data_gbas
            
        state.sort_column = res.sort_column;
    },  
    setLoad(state, load){
        state.load = load;
    },
    setLoadExcel(state, load){
        state.load_excel = load;
    },
    setColumn(state, res){
        state.column = res.column; 
    },
    select_export(state, val){   
        if(val === "clear"){
            state.select_export = getDefaultState().select_export; 
        }else{
            if(val.key === 'checkAll'){
                const temp = {
                    key : 'listID',
                    value : val.value && state.select_export.isSelect ? state.select_export.listID : []
                }
                state.select_export[temp.key] = temp.value;
            }
            state.select_export[val.key] = val.value;
        }
    },
    clearState(state){
        Object.assign(state, getDefaultState())
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}