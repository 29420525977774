import InterService from '../services/inter.service';


const getDefaultState = () => {
    return {
        agency: {
            'agency_id': [],
            'country_id' : []
        },
    }
}

const state = getDefaultState()

const getters = {
    agency: state => state.agency,
};

const actions = {
    loadDefaultAgency({ commit }) {
        return InterService.getDefaultAgency().then(
            res => {
                commit('setUserAgency', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    setDefaultAgency({ commit }, payload) {
        commit('updateUserAgency', payload);

        return InterService.setDefaultAgency(payload).then(
            res => {
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },


};
const mutations = {
    setUserAgency(state, res) {
        state.agency = res;
    },
    updateUserAgency(state, res) {
        if (state.agency[res.type] && state.agency[res.type].includes(res.id)) {
            let index = state.agency[res.type].indexOf(res.id)
            if (index > -1) {
                state.agency[res.type].splice(index, 1);
            }
        } else {
            state.agency[res.type].push(res.id)
        }
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
