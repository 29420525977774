import TranslationService from '../services/translation.service';

const getDefaultState = () => {
    return {        
        loading: {},
        translate_pdf_return: {},
        error: false,
        process: {}
    }
} 

const state = getDefaultState()

const getters = {    
    loading: state => state.loading,
    translate_pdf_return: state => state.translate_pdf_return,
    error: state => state.error,
    process: state => state.process
};

const actions = {
    translate_pdf({ commit }, payLoad) {
        let source_file = payLoad.document_path
        commit('set_loading', { source_file: source_file, status: true })
        return TranslationService.translate_pdf(payLoad).then(
            res => {
                if(res.document_id && res.document_key){
                    // if translate file not exist
                    payLoad["document_id"] = res.document_id
                    payLoad["document_key"] = res.document_key

                    let delay = setInterval(() => {
                        TranslationService.check_status_translation(payLoad).then(
                            res => {
                                if (res.status == "done" && state.loading[source_file]) {
                                    clearInterval(delay);
                                    commit('set_loading', { source_file: source_file, status: false })
                                    commit('set_process', {source_file:source_file, result:res, reset:true} )
                                    TranslationService.download_translate_pdf(payLoad).then(
                                        res => {
                                            commit('set_translate_pdf_return', {source_file:source_file, url_return:res})
                                            return Promise.resolve(res);
                                        },
                                        error => {
                                            return Promise.reject(error);
                                        }
                                    )
                                } else if(res.seconds_remaining != 2147483647){
                                    commit('set_process', {source_file:source_file, result:res, reset:false} )
                                    console.log(res.status, res.seconds_remaining)
                                }
                            },
                            error => {
                                commit('set_loading', { source_file: source_file, status: false })
                                commit('set_error', true)
                                clearInterval(delay);
                                return Promise.reject(error);
                            }
                        )
                    }, 5000);

                } else if(res.url_return && res.source_file)  {
                    // if translate file exist
                    commit('set_translate_pdf_return', res)
                    commit('set_loading', { source_file: source_file, status: false })
                    return Promise.resolve(res);
                } else {
                    commit('set_loading', { source_file: source_file, status: false })
                    commit('set_error', true)
                    return Promise.reject(res);
                }
            },
            error => {
                commit('set_loading', { source_file: source_file, status: false })
                commit('set_error', true)
                return Promise.reject(error);
            }
        )
    },
    set_error({ commit }, param) {
        commit('set_error', param)
    },
};
const mutations = {
    set_process(state, param, reset) {
        if (!state.process[param.source_file] || reset) {
            state.process[param.source_file] = {}
        }
        if(!reset){
            if (!state.process[param.source_file]['max_seconds_remaining']) {
                state.process[param.source_file]['max_seconds_remaining'] = param.result.seconds_remaining 
            }
            state.process[param.source_file]['status'] = param.result.status
            state.process[param.source_file]['seconds_remaining'] = param.result.seconds_remaining 
        }
        
    },
    set_translate_pdf_return(state, param) {
        state.translate_pdf_return[param.source_file] = param.url_return
    },
    set_loading(state, param) {
        state.loading[param.source_file] = param.status
    },
    set_error(state, param) {
        state.error = param
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


