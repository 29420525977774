import api from './api'; 
import authHeader from './auth-header';  

class TranslationService { 
    translate_pdf(param) {
        return api
            .post('translate/upload', param, {headers: authHeader()})
            .then(response => {
                return response.data
            })
            .catch(error => {
                return Promise.reject(error)
            });
    }
    check_status_translation(params) {
         return api
            .post('translate/check_status_translation', params, {headers: authHeader()})
            .then(response => {
                return response.data
            })
            .catch(error => {
                return Promise.reject(error)
            });
    }

    download_translate_pdf(param) {
        return api
            .post('translate/download', param, {headers: authHeader()})
            .then(response => {
                return response.data
            })
            .catch(error => {
                return Promise.reject(error)
            });
    }
}

export default new TranslationService();
