import api from './api'; 
import authHeader from './auth-header';  

class DetailService { 
    detail(param) { 
        return api
        .get(`detail/${param.id}/${param.agency}`, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            return Promise.reject(error)
        });
    }
    essais_clinique_popup(param){
        return api
        .get(`essais_clinique_popup/${param.id}/${param.agency}`, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    reimbursement_popup(param){
        return api
        .get(`reimbursement_popup/${param.drug_id}/${param.agency_model}`, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }

    update_view_enabled(param){
        return api
        .post('detail/update_view_enabled', param, { headers: authHeader() })
        .then(response => {   
            return response.data
        })
        .catch(error  => {            
            console.log(error)
            return Promise.reject(error)
        });
    }

    view_enabled() { 
        return api
        .post('detail/view_enabled', null, { headers: authHeader() })
        .then(response => {  
            return response.data
        })
        .catch(error  => {            
            console.log(error)
            return Promise.reject(error)
        });
    }
}

export default new DetailService();
