import api from './api'; 
import authHeader from './auth-header';  

class DidYouMeanService {
    getDidYouMean(data) { 
        console.log(data)
        return api
        .post('did_you_mean', data, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }

   
}

export default new DidYouMeanService();
