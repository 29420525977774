import { createRouter, createWebHistory } from 'vue-router';
import { isLogin, isUserFrench, isUserInter, homePage, isAdmin  } from '@/utils';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Auth/Login.vue')
    },
    {
        path: '/register',
        alias: '/register/',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '@/views/Auth/Register.vue')
    },
    {
        path: '/redirect_from_prismaccess',
        name: 'login_auto', 
        component: () => import(/* webpackChunkName: "login_auto" */ '@/views/Auth/LoginAuto.vue')
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "forgot_password" */ '@/views/Auth/ForgotPassword.vue')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "reset_password" */ '@/views/Auth/ResetPassword.vue')
    },   
    {
        path: '/oauth/callback',
        alias: '/oauth/callback/',
        name: 'callback',
        component: () => import(/* webpackChunkName: "callback" */ '@/views/Auth/Callback.vue')
    },     
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile.vue')
    },  
    {
        path: '/',
        name: 'simple', 
        meta : {userFrench : true},
        alias: '/simple',
        component: () => import(/* webpackChunkName: "simple" */ '@/views/simple.vue')
    }, 
    {
        path: '/advance',
        name: 'advance', 
        meta : {userFrench : true},
        component: () => import(/* webpackChunkName: "advance" */ '@/views/advance.vue')
    },
    {
        path: '/result/:base64Search/:type',
        name: 'result', 
        component: () => import(/* webpackChunkName: "result" */ '@/views/result.vue')
    },   
    {
        path: '/advanced_result_prix/:base64Search',
        name: 'advanced_result_prix',     
        meta : {userFrench : true},
        component: () => import(/* webpackChunkName: "advanced_result_prix" */ '@/views/advanced_result/Prix.vue')
    },
    {
        path: '/advanced_result_ceesp/:base64Search',
        name: 'advanced_result_ceesp',    
        meta : {userFrench : true},
        component: () => import(/* webpackChunkName: "advanced_result_ceesp" */ '@/views/advanced_result/Ceesp.vue')
    },
    {
        path: '/advanced_result_rtu/:base64Search',
        name: 'advanced_result_rtu',    
        meta : {userFrench : true},
        component: () => import(/* webpackChunkName: "advanced_result_rtu" */ '@/views/advanced_result/Rtu.vue')
    },  
    {
        path: '/inter',
        name: 'inter', 
        meta : {UserInter : true},
        component: () => import(/* webpackChunkName: "inter" */ '@/views/inter.vue')
    },
    {
        path: '/detail/:id/:agency',
        name: 'detail', 
        component: () => import(/* webpackChunkName: "detail" */ '@/views/detail.vue')
    },
    {
        path: '/advanced_result_atu_postatu/:base64Search',
        name: 'advanced_result_atu_postatu',    
        meta : {userFrench : true},
        component: () => import(/* webpackChunkName: "advanced_result_atu_postatu" */ '@/views/advanced_result/AtuPostatu.vue') 
    },
    {
        path: '/advanced_result/:base64Search',
        name: 'advanced_result',    
        meta : {userFrench : true},
        component: () => import(/* webpackChunkName: "advanced_result" */ '@/views/advanced_result/Transparence.vue')
    },
    {
        path: '/advanced_result_trs_ceesp/:base64Search',
        name: 'advanced_result_transparence_ceesp',   
        meta : {userFrench : true}, 
        component: () => import(/* webpackChunkName: "advanced_result_transparence_ceesp" */ '@/views/advanced_result/TransparenceCeesp.vue')
    },
    {
        path: '/advanced_result_prise_en_charge/:base64Search',
        name: 'advanced_result_prise_en_charge',    
        meta : {userFrench : true},
        component: () => import(/* webpackChunkName: "advanced_result_prise_en_charge" */ '@/views/advanced_result/PriseEnCharge.vue')
    },
    {
        path: '/userkeyword',
        name: 'userkeyword',
        component: () => import(/* webpackChunkName: "user_keyword" */ '@/views/userkeyword.vue')
    },
    {
        path: '/my-document',
        name: 'my-document',
        component: () => import(/* webpackChunkName: "my_document" */ '@/views/myDocument.vue')
    },    
    {
        path: '/hta_roadmap/:country',
        name: 'hta_roadmap',
        meta : {UserInter : true},
        component: () => import(/* webpackChunkName: "hta_roadmap" */ '@/views/hta_roadmap.vue')
    },       
    {
        path: '/time_to_access/:country',
        name: 'time_to_access',
        component: () => import(/* webpackChunkName: "hta_roadmap" */ '@/views/time_to_access.vue')
    },
    {
        path: '/faq/',
        name: 'faq',
        meta: { UserInter: true },
        component: () => import(/* webpackChunkName: "faq" */ '@/views/faq.vue')
    },
    { 
        path: "/:catchAll(.*)",
        name: 'NotFound', 
        component: () => import(/* webpackChunkName: "notFound" */ '@/views/notFound.vue')
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,

    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
     
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login', '/redirect_from_prismaccess', '/register', '/register/', '/forgot-password', '/reset-password', '/oauth/callback'];
    console.log(to.path)
    const authRequired = !publicPages.includes(to.path);
    
    if (authRequired && !isLogin()) {
        localStorage.redirectTo = to.path
        return next('/login');
    }

    if(to.meta.userFrench && !isUserFrench()){
        return next(homePage());
    }
    if(to.meta.UserInter && !isUserInter()){
        return next(homePage());
    }    
    if(to.meta.isAdmin && !isAdmin()){
        return next(homePage());
    }
    

    next();
})

export default router