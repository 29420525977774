import DetailService from '../services/detail.service';

const getDefaultState = () => {
    return {        
        load : false,
        data : null,
        agency : '',
        drug_id: 0,
        error : '',
        data_essais_clinique : {},
        data_reimbursement : {},
        show_block : [],
        select_all : false,
        view_enabled: [],
        access_agency: null
    }
} 

const state = getDefaultState()

const getters = {    
    load: state => state.load,
    agency: state => state.agency,
    drug_id: state => state.drug_id,
    data: state => state.data,
    error: state => state.error,
    data_essais_clinique : state => state.data_essais_clinique[state.agency] ? state.data_essais_clinique[state.agency][state.drug_id] : undefined,
    data_reimbursement : state => state.data_reimbursement[state.agency] ? state.data_reimbursement[state.agency][state.drug_id] : undefined,
    show_block : state => state.show_block,
    select_all : state => state.select_all,
    view_enabled: state => state.view_enabled,
    access_agency: state => state.access_agency
};

const actions = {
    getdata({ commit }, payLoad) { 
        commit('setLoad', true);
        return DetailService.detail(payLoad).then(
            res => { 
                commit('setData', res);                
                commit('set_view_enabled', res.view_enabled);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                console.log(error)
                return Promise.reject(error);
            }
        )
    },
    get_data_essais_clinique({commit}, payLoad) {
        commit('setLoad', true);
        return DetailService.essais_clinique_popup(payLoad).then(
            res => { 
                commit('set_essais_clinique', res)
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    get_data_reimbursement({commit}, payLoad) {
        commit('setLoad', true);
        return DetailService.reimbursement_popup(payLoad).then(
            res => { 
                commit('set_reimbursement', res)
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    set_drug_id({commit}, param){
        commit('set_drug_id', param)
    },
    set_agency({commit}, param){
        commit('set_agency', param)
    },
    set_show_block({commit}, param){
        commit('set_show_block', param)
    },
    set_select_all({commit}, param){
        commit('set_select_all', param)
    },
    clearState({commit}){
        commit('clearState')
    },    
    update_view_enabled({ commit }, payLoad) { 
        return DetailService.update_view_enabled(payLoad).then(
            res => {                
                commit('set_view_enabled',res); 
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },  
    set_view_enabled({ commit },) {
        return DetailService.view_enabled().then(
            res => {
                commit('set_view_enabled', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    }
};
const mutations = {
    setLoad(state, load){
        state.load = load;
    },
    setData(state, param){
        state.data = param.data;
        state.agency = param.agency;
        state.drug_id = param.drug_id;
        state.error = param.error;
        state.list_document_exist = param.list_document_exist;
        state.access_agency = param.access_agency
    },
    set_essais_clinique(state, res){
        let data_essais_clinique = {}
        if(res.agency in state.data_essais_clinique){
            data_essais_clinique = state.data_essais_clinique[res.agency] 
        }
        data_essais_clinique[res.drug_id] = res.data.essais_clinique;
        state.data_essais_clinique[res.agency] = data_essais_clinique;
        // Vue.set(data_essais_clinique, res.drug_id, res.data.essais_clinique)
        // Vue.set(state.data_essais_clinique, res.agency, data_essais_clinique)
    },
    set_reimbursement(state, res){
        let data_reimbursement = {}
        if(res.agency in state.data_reimbursement){
            data_reimbursement = state.data_reimbursement[res.agency] 
        }
        data_reimbursement[res.drug_id] = res.data.reimbursement;
        state.data_reimbursement[res.agency] = data_reimbursement;
        //Vue.set(data_reimbursement, res.drug_id, res.data.reimbursement)
        //Vue.set(state.data_reimbursement, res.agency, data_reimbursement)
    },
    set_drug_id(state, param){
        state.drug_id = param
    },
    set_agency(state, param){
        state.agency = param
    },
    set_show_block(state, param){
        state.show_block = param
    },
    set_select_all(state, param){
        state.select_all = param
    },
    clearState(state){
        Object.assign(state, getDefaultState())
    },    
    set_view_enabled(state, param) {
        state.view_enabled = param;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
