import api from './api'; 
import authHeader from './auth-header';  

class AgencyService {
    get_list_country(){
        return api.get('get_list_country')
          .then(response => {  
              return response.data;
          })
          .catch(error  => {            
              console.log(error);
              return Promise.reject(error);
          });
    }
    getGroupAgency(){
        return api
        .get('get_group_agency', { headers: authHeader() })
        .then(response => {      
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    getAllAgencies(){
        return api
        .get('get_agencies', { headers: authHeader() })
        .then(response => {      
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    getCountryByIconFlag(param){
        return api
        .post(`get_country_by_icon_flag`, param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
}

export default new AgencyService();
