import api from './api'; 
import authHeader from './auth-header';

class AdvancedResultService {
    prix_list_ss_coll() {
        return api
            .get(`advanced/prix_list_ss_coll`, { headers: authHeader() })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_prix(param) {
        let page = "";
        if ('page' in param) {
            page = `?page=${param.page}`;
            delete param.page;
        }
        return api
            .post(`advanced/prix${page}`, param, { headers: authHeader() })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_prix_excel(param, fileName) {
        return api
            .post(`advanced/prix?export=true`, param, { headers: authHeader(), responseType: 'blob' })
            .then(response => {
                const file = new Blob([response.data]);
                const url = window.URL.createObjectURL(file)
                const a = document.createElement('a')
                document.body.appendChild(a)
                a.href = url
                a.download = fileName + ".xlsx"
                a.target = '_blank'
                a.click()
                a.remove()
                window.URL.revokeObjectURL(url)

            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_ceesp(param) {
        let page = "";
        if ('page' in param) {
            page = `?page=${param.page}`;
            delete param.page;
        }
        return api
            .post(`advanced/ceesp${page}`, param, { headers: authHeader() })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_ceesp_excel(param, fileName) {
        return api
            .post(`advanced/ceesp?export=true`, param, { headers: authHeader(), responseType: 'blob' })
            .then(response => {
                const file = new Blob([response.data]);
                const url = window.URL.createObjectURL(file)
                const a = document.createElement('a')
                document.body.appendChild(a)
                a.href = url
                a.download = fileName + ".xlsx"
                a.target = '_blank'
                a.click()
                a.remove()
                window.URL.revokeObjectURL(url)

            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_rtu(param) {
        let page = "";
        if ('page' in param) {
            page = `?page=${param.page}`;
            delete param.page;
        }
        return api
            .post(`advanced/rtu${page}`, param, { headers: authHeader() })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_rtu_excel(param, fileName) {
        return api
            .post(`advanced/rtu?export=true`, param, { headers: authHeader(), responseType: 'blob' })
            .then(response => {
                const file = new Blob([response.data]);
                const url = window.URL.createObjectURL(file)
                const a = document.createElement('a')
                document.body.appendChild(a)
                a.href = url
                a.download = fileName + ".xlsx"
                a.target = '_blank'
                a.click()
                a.remove()
                window.URL.revokeObjectURL(url)

            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_atu_postatu(param) {
        let page = "";
        if ('page' in param) {
            page = `?page=${param.page}`;
            delete param.page;
        }
        return api
            .post(`advanced/atu_postatu${page}`, param, { headers: authHeader() })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_atu_postatu_excel(param, fileName) {
        return api
            .post(`advanced/atu_postatu?export=true`, param, { headers: authHeader(), responseType: 'blob' })
            .then(response => {
                const file = new Blob([response.data]);
                const url = window.URL.createObjectURL(file)
                const a = document.createElement('a')
                document.body.appendChild(a)
                a.href = url
                a.download = fileName + ".xlsx"
                a.target = '_blank'
                a.click()
                a.remove()
                window.URL.revokeObjectURL(url)

            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_transparence_ceesp(param) {
        let page = "";
        if ('page' in param) {
            page = `?page=${param.page}`;
            delete param.page;
        }
        return api
            .post(`advanced/transparence_ceesp${page}`, param, { headers: authHeader() })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_transparence_ceesp_excel(param, fileName) {
        return api
            .post(`advanced/transparence_ceesp?export=true`, param, { headers: authHeader(), responseType: 'blob' })
            .then(response => {
                const file = new Blob([response.data]);
                const url = window.URL.createObjectURL(file)
                const a = document.createElement('a')
                document.body.appendChild(a)
                a.href = url
                a.download = fileName + ".xlsx"
                a.target = '_blank'
                a.click()
                a.remove()
                window.URL.revokeObjectURL(url)

            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_transparence(param) {
        let page = "";
        if ('page' in param) {
            page = `?page=${param.page}`;
            delete param.page;
        }
        return api
            .post(`advanced/transparence${page}`, param, { headers: authHeader() })
            .then(response => {
                console.log('=====================');
                console.log(response);
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_transparence_excel(param, fileName) {
        return api
            .post(`advanced/transparence?export=true`, param, { headers: authHeader(), responseType: 'blob' })
            .then(response => {
                const file = new Blob([response.data]);
                const url = window.URL.createObjectURL(file)
                const a = document.createElement('a')
                document.body.appendChild(a)
                a.href = url
                a.download = fileName + ".xlsx"
                a.target = '_blank'
                a.click()
                a.remove()
                window.URL.revokeObjectURL(url)

            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_prise_en_charge(param) {
        let page = "";
        if ('page' in param) {
            page = `?page=${param.page}`;
            delete param.page;
        }
        return api
            .post(`advanced/prise_en_charge${page}`, param, { headers: authHeader() })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_prise_en_charge_excel(param, fileName) {
        return api
            .post(`advanced/prise_en_charge?export=true`, param, { headers: authHeader(), responseType: 'blob' })
            .then(response => {
                const file = new Blob([response.data]);
                const url = window.URL.createObjectURL(file)
                const a = document.createElement('a')
                document.body.appendChild(a)
                a.href = url
                a.download = fileName + ".xlsx"
                a.target = '_blank'
                a.click()
                a.remove()
                window.URL.revokeObjectURL(url)

            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_essais_clinique(param) {
        let page = "";
        if ('page' in param) {
            page = `?page=${param.page}`;
            delete param.page;
        }
        return api
            .post(`advanced/result_essais_clinique${page}`, param, { headers: authHeader() })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    result_essais_clinique_excel(param, fileName) {
        return api
            .post(`advanced/result_essais_clinique?export=true`, param, { headers: authHeader(), responseType: 'blob' })
            .then(response => {
                const file = new Blob([response.data]);
                const url = window.URL.createObjectURL(file)
                const a = document.createElement('a')
                document.body.appendChild(a)
                a.href = url
                a.download = fileName + ".xlsx"
                a.target = '_blank'
                a.click()
                a.remove()
                window.URL.revokeObjectURL(url)

            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }
    save_column(param) {
        return api
            .post('advanced/save_column', param, { headers: authHeader() })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }

    column_keyword(param) {
        return api
            .post('get_column_user_keyword', { keyword_id: param }, { headers: authHeader() })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }

}

export default new AdvancedResultService();