import api from './api'; 
import authHeader from './auth-header';  

class UserService { 
    save_keyword(param) { 
        return api
        .post('users/save_keyword', param, { headers: authHeader() })
        .then(response => {
            console.log(response);  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    
    
    access_agency(param) { 
        return api
        .post('users/access_agency', param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }

    userkeyword(){
        return api
        .post('users/userkeyword', null, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }

    remove_keyword(param){
        return api
        .delete('users/removekeyword/'+ param, { headers: authHeader() })
        // return api.delete('users/removekeyword/' + param)
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }

    update_keyword(param){
        return api
        .post('users/update_keyword', param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }

    count_user_keyword(param){
        return api
        .post('users/count_userkeyword', param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }

    send_feedback(param){
        return api
        .post('users/send_feedback', param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            return Promise.reject(error);
        });
    }
    userDocument(param){
        return api
        .post('users/userdocument', param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            return Promise.reject(error);
        });
    }
    userUpdateDocument(param){
        return api
        .post('users/updatedocument', param, { headers: authHeader() })
        .then(response => {
            return response.data;
        })
        .catch(error  => {            
            return Promise.reject(error);
        });
    }
    userData() {
        return api
        .get('users/profile', { headers: authHeader() })
        .then(response => {
            return response;
        })
        .catch(error  => {            
            return Promise.reject(error);
        });
    }
    userAlerts() {
        return api
        .get('users/alertemails', { headers: authHeader() })
        .then(response => {
            return response;
        })
        .catch(error  => {            
            return Promise.reject(error);
        });
    }
    userCreateAlert(param){
        return api
        .post('users/add_alertemails', param, { headers: authHeader() })
        .then(response => {
            return response;
        })
        .catch(error  => {            
            return Promise.reject(error);
        });
    }
    userRemoveAlert(param){
        return api
        .delete('users/remove_alert/'+ param, { headers: authHeader() })
        .then(response => {
            return response;
        })
        .catch(error  => {            
            return Promise.reject(error);
        });
    }
    userUpdate(param){
        return api
        .post('users/update', param, { headers: authHeader() })
        .then(response => {
            return response;
        })
        .catch((error)  => {         
            return Promise.reject(error.response.data);
        });
    }
   
    
}

export default new UserService();
