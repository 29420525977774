import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user : user }
  : { status: { loggedIn: false }, user: null };
const getDefaultState = () => {
    return {
        ...initialState,       
        load: false,
        internal_user_domains: [],
        is_internal_user: false
    }
}
const state = getDefaultState();

const getters = {
    user: state => state.user,
    is_internal_user: state => state.is_internal_user
};

const actions = {
    register({ commit }, user) {
        commit('setLoad', true);
        return AuthService.register(user).then(
            res => { 
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        );
    },
    login({ commit }, user) {
        return AuthService.login(user).then(
            user => { 
                commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    login_auto({ commit }, user) {
        return AuthService.login_auto(user).then(
            user => { 
                commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    login_oidc({ commit }, user) {
        return AuthService.login_oidc(user).then(
            user => { 
                commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    get_authorize_endpoint({ commit }) {
        return AuthService.get_authorize_endpoint().then(
            response => { 
                return Promise.resolve(response);
            },
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    get_domain_names_internal({ commit }){
        return AuthService.get_domain_names_internal().then(
            response => { 
                commit('listGetDomainNamesInternal', response)
                return Promise.resolve(response);
            },
            error => {
                commit('Failure');
                return Promise.reject(error);
            }
        );
    },
    check_user_oidc({commit}, username){
        return AuthService.check_user_oidc(username.username).then(
            response => { 
                commit('isInternalUser', response)
                return Promise.resolve(response);
            },
            error => {
                commit('Failure');
                return Promise.reject(error);
            }
        );
    },
    logout({ commit }) {
        AuthService.logout();
        commit('logout');
    },
    forgot_password({ commit }, email) {
        commit('setLoad', true);
        return AuthService.forgot_password(email).then(
            res => { 
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        );
    },
    reset_password({ commit }, param) {
        commit('setLoad', true);
        return AuthService.reset_password(param).then(
            res => { 
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        );
    },
};
const mutations = {
    loginSuccess(state, user) {
        state.status.loggedIn = true;
        state.user = user;
    },
    loginFailure(state) {
        state.status.loggedIn = false;
        state.user = null;
    },
    logout(state) {
        state.status.loggedIn = false;
        state.user = null;
    },
    registerSuccess(state) {
        state.status.loggedIn = false;
    },
    registerFailure(state) {
        state.status.loggedIn = false;
    },
    setLoad(state, load) {
        state.load = load;
    },
    listGetDomainNamesInternal(state, values) { 
        state.internal_user_domains = values;
    },
    isInternalUser(state, values) { 
        state.is_internal_user = values;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}