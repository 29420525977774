import ResultService from '../services/result.service';

const getDefaultState = () => {
    return {
        listAgency: [],
        load: false,
        load_agency: false,
        load_excel: false,
        Agency: "",
        count_all: 0,
        count_agency: [],
        count_filter: [],
        search_agency: [],
        access_agency: [],
        filters: '',
        data: {},
        data_overview: {},
        select_export: {},
        overviewReimbursementStatus: "",
        translateRembursemet: {},
        agency_sort: [],
        exist_column_overview: [],
        decisions_status: [],
        smr_request: [],
        asmr_request: []
    }
}

const state = getDefaultState()

const getters = {
    listAgency: state => state.listAgency,
    load: state => state.load,
    load_agency: state => state.load_agency,
    load_excel: state => state.load_excel,
    count_all: state => state.count_all,
    count_agency: state => state.count_agency,
    count_filter: state => state.count_filter,
    search_agency: state => state.search_agency,
    access_agency: state => state.access_agency,
    filters: state => state.filters,
    Agency: state => state.Agency,
    AgencyObj: state => {
        return state.listAgency.filter(item => item.agency_model == state.Agency)[0]
    },
    data: state => state.data[state.Agency],
    data_overview: state => state.data[state.overviewReimbursementStatus],
    select_export: state => state.select_export[state.Agency],
    select_export_checkAll: state => state.select_export[state.Agency] && state.select_export[state.Agency].checkAll ? state.select_export[state.Agency].checkAll : false,
    // select_export_isSelect: state => state.select_export[state.Agency].isSelect,
    overviewReimbursementStatus: state => state.overviewReimbursementStatus,
    translateRembursemet: state => state.translateRembursemet,
    agency_sort: state => state.agency_sort,
    exist_column_overview: state => state.exist_column_overview,
    decisions_status: state => state.decisions_status,
    smr_request: state => state.smr_request,
    asmr_request: state => state.asmr_request
};

const actions = {
    result({ commit }, payLoad) {
        commit('setLoad', true);
        return ResultService.result(payLoad).then(
            res => {
                commit('result', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    setFilters({ commit }, filters) {
        commit('setFilters', filters);
    },
    setOverviewReimbursementStatus({ commit }, overviewReimbursementStatus) {
        commit('setOverviewReimbursementStatus', overviewReimbursementStatus);
    },
    setExistColumnOverview({ commit }, exist_column_overview) {
        commit('setExistColumnOverview', exist_column_overview);
    },
    setTranslateRembursemet({ commit }, translateRembursemet) {
        commit('setTranslateRembursemet', translateRembursemet);
    },
    setAgency({ commit }, agency) {
        commit('setAgency', agency);
    },
    setActiveAgency({ commit }, Agency) {
        commit('setActiveAgency', Agency);
    },
    setUnActiveAgency({ commit }, Agency) {
        commit('setUnActiveAgency', Agency);
    },
    result_agency({ commit }, payLoad) {
        commit('setLoadAgency', true);
        return ResultService.result_agency(payLoad).then(
            res => {
                commit('setData', res);
                commit('setLoadAgency', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadAgency', false);
                return Promise.reject(error);
            }
        )
    },
    export_excel({ commit }, [payLoad, fileName]) {
        commit('setLoadExcel', true);
        return ResultService.export_excel(payLoad, fileName).then(
            res => {
                commit('setLoadExcel', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadExcel', false);
                return Promise.reject(error);
            }
        )
    },
    
    saveColumn({ commit }, payLoad) {
        commit('setLoadAgency', true);
        return ResultService.save_column(payLoad).then(
            res => {
                commit('setDataColumn', res);
                commit('setLoadAgency', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadAgency', false);
                return Promise.reject(error);
            }
        )
    },
    save_agency_sort({ commit }, payload) {
        commit('setLoad', true);

        return ResultService.save_agency_sort(payload).then(
            res => {
                commit('setLoad', false);
                // commit('save_agency_sort', res)
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    // },
    select_export({ commit }, val) {
        commit('select_export', val);
    },
    select_export_isSelect({ commit }, val) {
        commit('select_export_isSelect', val);
    },
    select_export_checkAll({ commit }, val) {
        commit('select_export_checkAll', val);
    },
    cleardata({ commit }) {
        commit('cleardata');
    },
    clearState({ commit }) {
        commit('clearState');
    }
};
const mutations = {
    result(state, res) {
        state.count_all = res.count_all;
        state.access_agency = res.access_agency;
        state.search_agency = res.search_agency;
        state.count_agency = res.count_agency;
        state.agency_sort = res.agency_sort;
        state.listAgency = res.listAgency;
        const keySelectExport = {
            checkAll: false,
            isSelect: false,
            listID: []
        }
        state.select_export['Overview'] = keySelectExport;
        for (const agency of res.access_agency) {
            state.select_export[agency] = keySelectExport;
        }
    },
    setLoad(state, load) {
        state.load = load;
    },
    setLoadAgency(state, load) {
        state.load_agency = load;
    },
    setLoadExcel(state, load) {
        state.load_excel = load;
    },
    setAgency(state, Agency) {
        state.Agency = Agency;
    },
    setFilters(state, filters) {
        state.filters = filters;
    },
    setActiveAgency(state, Agency) {
        const index = state.search_agency.indexOf(Agency);
        if (index == -1) {
            state.search_agency.push(Agency);
        }
    },
    setUnActiveAgency(state, Agency) {
        const index = state.search_agency.indexOf(Agency);
        if (index > -1) {
            state.search_agency.splice(index, 1);
        }
    },

    setOverviewReimbursementStatus(state, overviewReimbursementStatus) {
        state.overviewReimbursementStatus = overviewReimbursementStatus;
    },

    setExistColumnOverview(state, exist_column_overview) {
        state.exist_column_overview = exist_column_overview;
    },

    setTranslateRembursemet(state, translateRembursemet) {
        state.translateRembursemet = translateRembursemet;
    },
    setData(state, res) {
        state.data[res.agency] = res;
        if (res.count_filter && Object.keys(res.count_filter).length > 0) {
            state.count_filter = res.count_filter;
        } else if (!state.filters) {
            state.count_filter = {}
        }

        if(res.decisions_status)
            state.decisions_status = res.decisions_status

        if(res.data_smr_asmr){
            state.smr_request = res.data_smr_asmr.smr
            state.asmr_request = res.data_smr_asmr.asmr
        }
    },
    setDataColumn(state, res) {
        state.data[res.agency]["column"] = res.column;
    },
    // save_agency_sort(state, res){
    //     state.agency_sort = res
    // },
    select_export(state, val) {
        const temp = {
            checkAll: state.select_export[state.Agency].checkAll,
            isSelect: state.select_export[state.Agency].isSelect,
            listID: val
        }
        state.select_export[state.Agency] = temp
        //Vue.set(state.select_export, state.Agency, temp);
        
    },
    select_export_checkAll(state, val) {
        const temp = {
            checkAll: val,
            isSelect: state.select_export[state.Agency].isSelect,
            listID: val && state.select_export[state.Agency].isSelect ? state.select_export[state.Agency].listID : [],
        }
        state.select_export[state.Agency] = temp;
    },
    select_export_isSelect(state, val) {
        const temp = {
            checkAll: state.select_export[state.Agency].checkAll,
            isSelect: val,
            listID: state.select_export[state.Agency].listID,
        }
        state.select_export[state.Agency] = temp;
    },
    cleardata(state) {
        state.data = {};
    },
    clearState(state) {
        Object.assign(state, getDefaultState())
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}