import AdvancedResultService from '../services/advanced_result.service';

const getDefaultState = () => {
    return {
        type: "",
        load: false,
        load_excel: false,
        count: 0,
        data: {},
        column: [],
        default_column: [],
        reset_column: [],
        select_export: {
            isSelect: false,
            listID: [],
            checkAll: false
        },
        data_chart: [],
        load_chart: false,
        data_filter: "",
        show_filter: false,
        count_filter: 0,
        sort_column : {}

    }
}

const getDefaultStatePrix = () => {
    return {
        prix_count: 0,
        prix_data: {},
        prix_list_ss_coll: [],
        prix_select_export: {
            isSelect: false,
            listID: [],
            checkAll: false
        },
        prix_column: [],
        prix_default_column: [],
        prix_reset_column: [],
        prix_load: false,


    }
}

const state = {...getDefaultState(), ...getDefaultStatePrix() }

const getters = {
    type: state => state.type,
    load: state => state.load,
    load_excel: state => state.load_excel,
    count: state => state.count,
    data: state => state.data,
    select_export: state => state.select_export,
    column: state => state.column,
    default_column: state => state.default_column,
    reset_column: state => state.reset_column,
    data_chart: state => state.data_chart,
    load_chart: state => state.load_chart,
    data_filter: state => state.data_filter,
    show_filter: state => state.show_filter,
    count_filter: state => state.count_filter,


    prix_count: state => state.prix_count,
    prix_data: state => state.prix_data,
    prix_list_ss_coll: state => state.prix_list_ss_coll,
    prix_select_export: state => state.prix_select_export,
    prix_column: state => state.prix_column,
    prix_default_column: state => state.prix_default_column,
    prix_reset_column: state => state.prix_reset_column,
    prix_load: state => state.prix_load,

    sort_column: state => state.sort_column

};

const actions = {

    prix_list_ss_coll({ commit }) {
        return AdvancedResultService.prix_list_ss_coll().then(
            res => {
                commit('prix_list_ss_coll', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    result_prix({ commit }, payLoad) {
        commit('setLoadPrix', true);
        return AdvancedResultService.result_prix(payLoad).then(
            res => {
                commit('prix_result', res);
                commit('setLoadPrix', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadPrix', false);
                return Promise.reject(error);
            }
        )
    },
    result_prix_excel({ commit }, [payLoad, fileName]) {
        commit('setLoadExcel', true);
        return AdvancedResultService.result_prix_excel(payLoad, fileName).then(
            res => {
                commit('setLoadExcel', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadExcel', false);
                return Promise.reject(error);
            }
        )
    },
    result_ceesp({ commit }, payLoad) {
        commit('setLoad', true);
        return AdvancedResultService.result_ceesp(payLoad).then(
            res => {
                commit('result', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    result_ceesp_excel({ commit }, [payLoad, fileName]) {
        commit('setLoadExcel', true);
        return AdvancedResultService.result_ceesp_excel(payLoad, fileName).then(
            res => {
                commit('setLoadExcel', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadExcel', false);
                return Promise.reject(error);
            }
        )
    },
    result_rtu({ commit }, payLoad) {
        commit('setLoad', true);
        return AdvancedResultService.result_rtu(payLoad).then(
            res => {
                commit('result', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    result_rtu_excel({ commit }, [payLoad, fileName]) {
        commit('setLoadExcel', true);
        return AdvancedResultService.result_rtu_excel(payLoad, fileName).then(
            res => {
                commit('setLoadExcel', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadExcel', false);
                return Promise.reject(error);
            }
        )
    },
    result_atu_postatu({ commit }, payLoad) {
        commit('setLoad', true);
        return AdvancedResultService.result_atu_postatu(payLoad).then(
            res => {
                commit('result', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    result_atu_postatu_excel({ commit }, [payLoad, fileName]) {
        commit('setLoadExcel', true);
        return AdvancedResultService.result_atu_postatu_excel(payLoad, fileName).then(
            res => {
                commit('setLoadExcel', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadExcel', false);
                return Promise.reject(error);
            }
        )
    },
    result_atu_postatu_chart({ commit }, payLoad) {
        commit('setLoadChart', true);
        return AdvancedResultService.result_atu_postatu(payLoad).then(
            res => {
                commit('setChart', res);
                commit('setLoadChart', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadChart', false);
                return Promise.reject(error);
            }
        )
    },
    result_transparence({ commit }, payLoad) {
        commit('setLoad', true);
        return AdvancedResultService.result_transparence(payLoad).then(
            res => {
                commit('result', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    result_transparence_excel({ commit }, [payLoad, fileName]) {
        commit('setLoadExcel', true);
        return AdvancedResultService.result_transparence_excel(payLoad, fileName).then(
            res => {
                commit('setLoadExcel', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadExcel', false);
                return Promise.reject(error);
            }
        )
    },
    result_transparence_chart({ commit }, payLoad) {
        commit('setLoadChart', true);
        return AdvancedResultService.result_transparence(payLoad).then(
            res => {
                commit('setChart', res);
                commit('setLoadChart', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadChart', false);
                return Promise.reject(error);
            }
        )
    },
    result_transparence_ceesp({ commit }, payLoad) {
        commit('setLoad', true);
        return AdvancedResultService.result_transparence_ceesp(payLoad).then(
            res => {
                commit('result', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    result_transparence_ceesp_excel({ commit }, [payLoad, fileName]) {
        commit('setLoadExcel', true);
        return AdvancedResultService.result_transparence_ceesp_excel(payLoad, fileName).then(
            res => {
                commit('setLoadExcel', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadExcel', false);
                return Promise.reject(error);
            }
        )
    },
    result_prise_en_charge({ commit }, payLoad) {
        commit('setLoad', true);
        return AdvancedResultService.result_prise_en_charge(payLoad).then(
            res => {
                commit('result', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    result_prise_en_charge_excel({ commit }, [payLoad, fileName]) {
        commit('setLoadExcel', true);
        return AdvancedResultService.result_prise_en_charge_excel(payLoad, fileName).then(
            res => {
                commit('setLoadExcel', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadExcel', false);
                return Promise.reject(error);
            }
        )
    },
    saveColumn({ commit }, payLoad) {
        commit('setLoad', true);
        return AdvancedResultService.save_column(payLoad).then(
            res => {
                commit('setColumn', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    prix_saveColumn({ commit }, payLoad) {
        commit('setLoadPrix', true);
        return AdvancedResultService.save_column(payLoad).then(
            res => {
                commit('setColumnPrix', res);
                commit('setLoadPrix', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadPrix', false);
                return Promise.reject(error);
            }
        )
    },
    select_export({ commit }, val) {
        commit('select_export', val);
    },
    select_export_checkAll({ commit }, val) {
        commit('select_export_checkAll', val);
    },
    prix_select_export({ commit }, val) {
        commit('prix_select_export', val);
    },
    clearState({ commit }) {
        commit('clearState');
    },
    clearStatePrix({ commit }) {
        commit('clearStatePrix');
    },
    setType({ commit }, val) {
        commit('setType', val);
    },
    set_data_filter({ commit }, val) {
        commit('set_data_filter', val);
        commit('clearStatePrix');
    },
    set_show_filter({ commit }, val) {
        commit('set_show_filter', val);
    },
};
const mutations = {

    prix_result(state, res) {
        state.type = res.type;
        if (state.prix_count === 0) { // set first only
            state.prix_count = res.data.total;
        }
        state.prix_data = res.data;
        state.prix_column = res.column;
        state.prix_default_column = res.default_column;
        state.prix_reset_column = res.reset_column;
        state.sort_column = res.sort_column;
    },
    result(state, res) {
        state.type = res.type;
        if (state.count === 0) { // set first only
            state.count = res.data.total;
        }
        state.count_filter = res.data.total;
        state.data = res.data;
        state.column = res.column;
        state.default_column = res.default_column;
        state.reset_column = res.reset_column;
        state.sort_column = res.sort_column;
    },
    prix_list_ss_coll(state, data) {
        state.prix_list_ss_coll = data;
    },
    setType(state, type) {
        state.type = type;
    },
    setLoad(state, load) {
        state.load = load;
    },
    setLoadExcel(state, load) {
        state.load_excel = load;
    },
    setLoadPrix(state, load) {
        state.prix_load = load;
    },
    setLoadChart(state, load) {
        state.load_chart = load;
    },
    setChart(state, data) {
        state.data_chart = data
    },
    setColumn(state, res) {
        state.column = res.column;
    },
    setColumnPrix(state, res) {
        state.prix_column = res.column;
    },
    select_export(state, val) {
        if (val === "clear") {
            state.select_export = getDefaultState().select_export;
        } else {
            if (val.key === 'checkAll') {
                const temp = {
                    key: 'listID',
                    value: val.value && state.select_export.isSelect ? state.select_export.listID : []
                }
                state.select_export[temp.key] = temp.value;
                //Vue.set(state.select_export, temp.key, temp.value);
            }
            state.select_export[val.key] = val.value;
            //Vue.set(state.select_export, val.key, val.value);
        }
    },
    prix_select_export(state, val) {
        if (val === "clear") {
            state.prix_select_export = getDefaultStatePrix().prix_select_export;
        } else {
            if (val.key === 'checkAll') {
                const temp = {
                    key: 'listID',
                    value: val.value && state.prix_select_export.isSelect ? state.prix_select_export.listID : []
                }
                state.prix_select_export[temp.key] = temp.value;
                //Vue.set(state.prix_select_export, temp.key, temp.value);
            }
            state.prix_select_export[val.key] = val.value;
            //Vue.set(state.prix_select_export, val.key, val.value);
        }
    },
    set_data_filter(state, data_filter) {
        state.data_filter = data_filter;
    },
    set_show_filter(state, show_filter) {
        state.show_filter = show_filter;
    },
    clearState(state) {
        Object.assign(state, {...getDefaultState(), ...getDefaultStatePrix() })
    },
    clearStatePrix(state) {
        Object.assign(state, getDefaultStatePrix())
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}