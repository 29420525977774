import DidYouMeanService from '../services/did_you_mean.service';
 
const state = {
    did_you_mean : [],
    to_box_name : [],
    load_did_you_mean : false
};

const getters = {
    did_you_mean: state => state.did_you_mean,
    load_did_you_mean: state => state.load_did_you_mean,
    to_box_name : state => state.to_box_name,
};

const actions = {
    searchDidYouMean({ commit }, keyLoad) {
        console.log(keyLoad)
        return DidYouMeanService.getDidYouMean(keyLoad).then(
            res => { 
                commit('didYouMean', res);
                commit('toBoxName',[])
                if(res.length > 0){
                    commit('setLoadDidYouMean', true);
                }else{
                    commit('setLoadDidYouMean', false);
                }
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadDidYouMean', false);
                return Promise.reject(error);
            }
        )
    },
    toBoxName({ commit }, keyLoad){
        commit('toBoxName', keyLoad);
    },
    setLoadDidYouMean({ commit }, keyLoad){
        commit('setLoadDidYouMean', keyLoad);
    },
    resetDidYouMean({ commit }){
        commit('didYouMean', []);
    }
};
const mutations = {
    didYouMean(state, did_you_mean){
        state.did_you_mean = did_you_mean;
    },
    setLoadDidYouMean(state, load_did_you_mean){
        state.load_did_you_mean = load_did_you_mean;
    },
    toBoxName(state, to_box_name){
        state.to_box_name = to_box_name;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}