import AutocompleteService from '../services/autocomplete.service';
 
const state = {
    name : [],
    therapeutic : [],
    therapeutic_tree: [],
    dci : [],
    atc : [],
    firm : [],
    libelle : [], 
    libelle_dci : [],
    bs_medicament : [],
    bs_substance : [],
    tc_has_id : [],
    clinical_study_phase : [],
    clinical_study_measure_type : [],
    measure_category : [],
    bp_name : [],
    load : false
};

const getters = {
    name: state => state.name,
    therapeutic: state => state.therapeutic,
    therapeutic_tree: state => state.therapeutic_tree,
    atc: state => state.atc,
    dci: state => state.dci,
    firm: state => state.firm,
    libelle: state => state.libelle,
    libelle_dci: state => state.libelle_dci,
    bs_medicament: state => state.bs_medicament,
    bs_substance: state => state.bs_substance,
    tc_has_id: state => state.tc_has_id,
    clinical_study_phase: state => state.clinical_study_phase,
    clinical_study_measure_type: state => state.clinical_study_measure_type,
    measure_category: state => state.measure_category,
    bp_name: state => state.bp_name,
    load: state => state.load
};

const actions = {
    loadAutocomplete({ commit }, keyLoad) {
        commit('setLoad', true);
        return AutocompleteService.getAutocomplete(keyLoad).then(
            res => { 
                commit('GET_AUTOCOMPLETE', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
};
const mutations = {
    GET_AUTOCOMPLETE(state, res) { 
        if(res.name !== undefined){
            state.name = res.name;
        }
        if(res.therapeutic !== undefined){
            state.therapeutic = res.therapeutic;
        }
        if(res.therapeutic_tree !== undefined){
            state.therapeutic_tree = res.therapeutic_tree;
        }
        if(res.atc !== undefined){
            state.atc = res.atc;
        }
        if(res.dci !== undefined){
            state.dci = res.dci;
        }
        if(res.firm !== undefined){
            state.firm = res.firm;
        }
        if (res.libelle !== undefined){
            state.libelle = res.libelle;
        }
        if (res.libelle_dci !== undefined){
            state.libelle_dci = res.libelle_dci; 
        }
        if (res.bs_medicament != undefined){
            state.bs_medicament = res.bs_medicament;
        }
        if (res.bs_substance != undefined){
            state.bs_substance = res.bs_substance;
        }
        if (res.tc_has_id != undefined){
            state.tc_has_id = res.tc_has_id;
        }
        if (res.clinical_study_phase != undefined){
            state.clinical_study_phase = res.clinical_study_phase;
        }
        if(res.clinical_study_measure_type != undefined){
            state.clinical_study_measure_type = res.clinical_study_measure_type;
        }
        if (res.measure_category !== undefined){
            state.measure_category = res.measure_category;
        }
        if (res.bp_name !== undefined){
            state.bp_name = res.bp_name;
        }
        
        //state.status.loggedIn = true;
        //state.user = user;
    },
    setLoad(state, load){
        state.load = load;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}