import UserService from '../services/user.service';
import {integratePendo } from '../utils/pendo';

const state = {   
    load: false,
    saveKeywordSuccess: false,
    access_agency: [],
    quanUserKeyword: "",
    data_user_keywords:[],
    sendFeedbackSuccess : false,    
    feedback_msg : "",
    data_user_document : [],
    data_user_update_document :[],
    user_profile: null,
    user_alerts: [],
    create_alert: [],
    remove_alert: null,
    user_update: null

}

const getters = {
    load: state => state.load,
    saveKeywordSuccess: state => state.saveKeywordSuccess,
    access_agency: state => state.access_agency,  
    quanUserKeyword: state => state.quanUserKeyword,
    data_user_keywords: state => state.data_user_keywords,
    sendFeedbackSuccess : state => state.sendFeedbackSuccess,
    feedback_msg  : state => state.feedback_msg,
    data_user_document : state =>state.data_user_document,    
    data_user_update_document : state =>state.data_user_update_document,
    user_profile: state => state.user_profile,
    user_alerts: state => state.user_alerts,
    create_alert: state => state.create_alert,
    remove_alert: state => state.remove_alert,
    user_update: state => state.user_update,    
    export: state => state.user_profile ? state.user_profile.data.export : undefined
    
};

const actions = {
    saveKeyword({ commit, dispatch  }, payLoad) {
        commit('setLoad', true);
        return UserService.save_keyword(payLoad).then(
            res => {
                commit('saveKeywordSuccess', true);
                commit('setLoad', false);
                dispatch('countUserKeyword');
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    setSaveKeywordSuccess({ commit }, payLoad) {
        commit('saveKeywordSuccess', payLoad);
    },
    accessAgency({ commit },) {
        return UserService.access_agency().then(
            res => {
                commit('accessAgency', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    // setOpenModalEditKeyword({ commit }, payLoad) {
    //     commit('setOpenModalEditKeyword', payLoad);
    // },
    // setOpenModalRemoveKeyword({ commit }, payLoad) {
    //     commit('setOpenModalRemoveKeyword', payLoad);
    // },
    userKeyword({ commit }) {
        commit('setLoad', true);
        return UserService.userkeyword().then(
            res => {
                commit('setDataUserKeyword', res)
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    removeKeyword({  dispatch }, id) {        
        return UserService.remove_keyword(id).then(
            res => {
                // commit('setOpenModalRemoveKeyword', false);
                dispatch('countUserKeyword');
                dispatch('userKeyword');
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    update_keyword({  dispatch }, payLoad) { 
        return UserService.update_keyword(payLoad).then(
            res => {
                dispatch('userKeyword');
                // commit('setOpenModalEditKeyword', false);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    countUserKeyword({ commit }){
        return UserService.count_user_keyword().then(
            res => {
                commit('countUserKeyword', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    setpopup_feedback({ commit }, payLoad) {
        commit('setpopup_feedback', payLoad);               
    },
    send_feedback({ commit}, payLoad) { 
        commit('setLoad', true);
        return UserService.send_feedback(payLoad).then(
            res => {  
                console.log('send_feedback res...',res)
                commit('setLoad', false);
                commit('sendFeedbackSuccess', res.sent);     
                commit('setfeedback_msg', res.reason); 
                return Promise.resolve(res);
            },
            error => {                
                return Promise.reject(error);
            }
        )
    }, 
    userDocument({ commit },) {
        commit('setLoad', true);
        return UserService.userDocument().then(
            res => {
                commit('setDataUserDocument', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    userUpdateDocument({ commit }, payLoad) {
        return UserService.userUpdateDocument(payLoad).then(
            res => {
                commit('setDataUserUpdateDocument', res);
                // dispatch('userDocument');
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    userData({ commit },) {
        commit('setLoad', true);
        return UserService.userData().then(
            res => {
                commit('setUserData', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    userAlerts({ commit },) {
        commit('setLoad', true);
        return UserService.userAlerts().then(
            res => {
                commit('setUserAlerts', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    userCreateAlert({ commit }, payLoad) {
        commit('setLoad', true);
        return UserService.userCreateAlert(payLoad).then(
            res => {
                commit('setUserAlerts', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    userRemoveAlert({ commit }, payLoad) {
        commit('setLoad', true);
        return UserService.userRemoveAlert(payLoad).then(
            res => {
                commit('setUserRemoveAlert', res);
                commit('setUserAlerts', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },
    userUpdate({ commit }, payLoad) {
        commit('setLoad', true);
        return UserService.userUpdate(payLoad).then(
            res => {
                commit('setUserUpdate', res);
                commit('setUserData', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },


    
};
const mutations = {
    setLoad(state, load) {
        state.load = load;
    },
    saveKeywordSuccess(state, param) {
        state.saveKeywordSuccess = param;
    },
    accessAgency(state, param) {
        state.access_agency = param['access_agency'];
    },
    countUserKeyword(state, param){
        state.quanUserKeyword = param;
    },
    setDataUserKeyword(state, param){
        state.data_user_keywords = param;
    },
    sendFeedbackSuccess(state, param) {
        state.sendFeedbackSuccess = param;
    },
    setfeedback_msg(state,param){
        state.feedback_msg = param;
    },
    setDataUserDocument(state,param){
        state.data_user_document = param;
    },
    setDataUserUpdateDocument(state,param){
        state.data_user_update_document = param;
    },
    setUserData(state, param){
        integratePendo(param.data);
        state.user_profile = param;
    },
    setUserAlerts(state, param){
        state.user_alerts = param;
    },
    setUserCreateAlert(state, param){
        state.create_alert = param;
    },
    setUserRemoveAlert(state, param){
        state.remove_alert = param;
    },
    setUserUpdate(state, param){
        state.user_update = param;
    }


};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

