import api from './api'; 
import authHeader from './auth-header';  

class AutocompleteService {
    getAutocomplete(data) { 
        return api
        .post('autocomplete', { data }, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }

   
}

export default new AutocompleteService();
