
import AgencyService from '../services/agency.service';


const getDefaultState = () => {
    return {
        list_country: [],
        groupAgency : [],
        allAgencies: []
    }
}

const state = getDefaultState()

const getters = {
    list_country: state => state.list_country, 
    groupAgency: state => state.groupAgency, 
    allAgencies: state => state.allAgencies

};

const actions = {
    get_list_country({ commit }){
        return AgencyService.get_list_country().then(
            res => {
                commit('list_country', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        );
    }, 
    getgroupAgency({ commit }){
        return AgencyService.getGroupAgency().then(
            res => { 
                commit('getGroupAgency', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    getAllAgencies({ commit }){
        return AgencyService.getAllAgencies().then(
            res => { 
                commit('getAllAgencies', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    getCountryByIconFlag({ commit }, payLoad){
        return AgencyService.getCountryByIconFlag(payLoad).then(
            res => { 
                commit('list_country', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    }
};
const mutations = {
    list_country(state, list_country){
        state.list_country = list_country
    },
    getGroupAgency(state, res){
        state.groupAgency = res;
    },
    getAllAgencies(state, res){
        state.allAgencies = res
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
