import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales'


if(!localStorage.getItem('language')){
  localStorage.setItem('language', 'fr')
}

const app = createApp(App)
app.use(i18n)
app.use(router)
app.use(store)
app.mount('#app')