<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
export default {
  
}
</script>

<style lang="postcss">

@import "./assets/css/style.css";
@import "./assets/css/search.css";
@import "./assets/css/result.css";
@import "./assets/css/icon.css";
@import "./assets/css/custom.css";
@import "./assets/css/account.css";
@import "./assets/css/fontawesome-5.11.2/css/all.min.css";
@import "./assets/css/fontawesome-5.11.2/css/fontawesome.min.css";

</style>

