import api from './api'; 
import authHeader from './auth-header';  

class ResultService {
    result(param) { 
        return api
        .post('result', { base64Search : param.base64Search.replace('/\-/g', '+').replace('/_/g', '/'), type : param.type  }, { headers: authHeader() }) // eslint-disable-line
        .then(response => {  
            return response.data;
        })
        .catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
    }
    result_agency(param) { 
        let page = "";
        if('page' in param){
            page = `?page=${param.page}`;
            delete param.page;
        }
        return api
        .post(`result_agency${page}`, param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    export_excel(param, fileName) {        
        return api
        .post(`result_agency?export=true`, param, { headers: authHeader(), responseType: 'blob' })
        .then(response => {
            console.log(response);
           // const fileNamex = decodeURI(response.headers['Content-Disposition'].split("=")[1]);
            const file = new Blob([response.data]);
            const url = window.URL.createObjectURL(file)
            const a = document.createElement('a')
            document.body.appendChild(a) 
            a.href = url
            a.download = fileName + ".xlsx"
            a.target = '_blank' 
            a.click()
            a.remove() 
            window.URL.revokeObjectURL(url)

        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    save_column(param) { 
        return api
        .post('save_column', param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    save_agency_sort(param){
        return api
        .post('save_agency_sort', param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    // save_keyword(param) { 
    //     return api
    //     .post('save_keyword', param, { headers: authHeader() })
    //     .then(response => {  
    //         return response.data;
    //     })
    //     .catch(error  => {            
    //         console.log(error);
    //         return Promise.reject(error);
    //     });
    // }
    result_report(param) { 
        let page = "";
        if('page' in param){
            page = `?page=${param.page}`;
            delete param.page;
        }
        return api
        .post(`result_report${page}`, param, { headers: authHeader() })
        .then(response => {  
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
    result_report_excel(param, fileName) {        
        return api
        .post(`result_report?export=true`, param, { headers: authHeader(), responseType: 'blob' })
        .then(response => {            
           // const fileNamex = decodeURI(response.headers['Content-Disposition'].split("=")[1]);
            const file = new Blob([response.data]);
            const url = window.URL.createObjectURL(file)
            const a = document.createElement('a')
            document.body.appendChild(a) 
            a.href = url
            a.download = fileName + ".xlsx"
            a.target = '_blank' 
            a.click()
            a.remove() 
            window.URL.revokeObjectURL(url)

        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
   
}

export default new ResultService();
