import ResultService from '../services/result.service';

const getDefaultState = () => {
    return {
        type : "",
        count : 0,
        data : {}, 
        select_export : {
            isSelect : false,
            listID : [],
            checkAll : false
        },
        column : [],
        default_column : [],
        reset_column : [],
        load : false, 
        load_excel : false, 
        all_name : [],
        all_indexation : [],

    }
}

const state = getDefaultState()

const getters = {
    type: state => state.type,
    count: state => state.count,
    data: state => state.data,    
    select_export: state => state.select_export, 
    column: state => state.column,   
    default_column: state => state.default_column, 
    reset_column: state => state.reset_column, 
    load: state => state.load,
    load_excel: state => state.load_excel,
    all_name: state => state.all_name,    
    all_indexation: state => state.all_indexation,    
};

const actions = {     
    result_report({ commit }, payLoad) { 
        commit('setLoad', true);
        return ResultService.result_report(payLoad).then(
            res => { 
                commit('result', res);
                commit('setLoad', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },    
    result_report_excel({ commit }, [payLoad, fileName]) { 
        commit('setLoadExcel', true);
        return ResultService.result_report_excel(payLoad, fileName).then(
            res => { 
                commit('setLoadExcel', false);
                return Promise.resolve(res);
            },
            error => {
                commit('setLoadExcel', false);
                return Promise.reject(error);
            }
        )
    },
    saveColumn({ commit }, payLoad) { 
        commit('setLoad', true);
        return ResultService.save_column(payLoad).then(
            res => { 
                commit('setColumn', res);                
                commit('setLoad', false);   
                return Promise.resolve(res);
            },
            error => {
                commit('setLoad', false);
                return Promise.reject(error);
            }
        )
    },    
    select_export({ commit }, val) {
        commit('select_export', val);
    },     
    clearState({ commit }){
        commit('clearState');
    },   
};
const mutations = {
    
     
    result(state, res) {  
        if(state.count === 0){ // set first only
            state.count = res.data.total; 
        }      
        state.data = res.data; 
        state.type = res.type;   
        state.column = res.column;  
        state.default_column = res.default_column; 
        state.reset_column = res.reset_column; 
        state.all_name = res.all_name; 
        state.all_indexation = res.all_indexation; 
        
    },  
    setLoad(state, load){
        state.load = load;
    },
    setLoadExcel(state, load){
        state.load_excel = load;
    },
    setColumn(state, res){
        state.column = res.column; 
    },
    select_export(state, val){   
        if(val === "clear"){
            state.select_export = getDefaultState().select_export; 
        }else{
            if(val.key === 'checkAll'){
                const temp = {
                    key : 'listID',
                    value : val.value && state.select_export.isSelect ? state.select_export.listID : []
                }
                state.select_export[temp.key] = temp.value;
            }
            state.select_export[val.key] = val.value;
        }
    },
    clearState(state){
        Object.assign(state, getDefaultState())
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}