import api from './api'; 
import authHeader from './auth-header';  

class InterService {

    getDefaultAgency() { 
        return api
        .post('get_default_agency', {}, { headers: authHeader() })
        .then(response => {      
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }


    setDefaultAgency(data) { 
        return api
        .post('set_default_agency',  data , { headers: authHeader() })
        .then(response => {      
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }

    setMemoryKeyword(data) { 
        return api
        .post('set_memory_keyword',  data , { headers: authHeader() })
        .then(response => {      
            return response.data;
        })
        .catch(error  => {            
            console.log(error);
            return Promise.reject(error);
        });
    }
}

export default new InterService();
