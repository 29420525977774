import HtaRoadmapService from '../services/hta_roadmap.service';

const state = {
    datas : {},
    country : ''
};

const getters = {
    datas: state => state.datas[state.country],
};

const actions = {
    get_datas({ commit }, keyLoad) {
        return HtaRoadmapService.getData(keyLoad).then(
            res => { 
                commit('get_datas', res);
                return Promise.resolve(res);
            },
            error => {
                return Promise.reject(error);
            }
        )
    },
    setCountry({ commit }, country){
        commit('setCountry', country)
    }
};
const mutations = {
    get_datas(state, res){
        state.country = res.country
        state.datas[res.country] = res        
    },
    setCountry(state, country){
        state.country = country
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}