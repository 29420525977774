import Vuex from 'vuex'
import auth from "./auth.module"
import autocomplete from "./autocomplete.module"
import did_you_mean from "./did_you_mean.module"
import header from "./header.module"
import result from "./result.module"
import report from "./report.module"
import advanced_result from "./advanced_result.module"
import users from "./users.module"
import detail from "./detail.module"
import inter from "./inter.module"
import essais_clinique from "./essais_clinique.module"
import hta_roadmap from "./hta_roadmap.module"
import agency from "./agency.module"
import translation from "./translation.module"


export default Vuex.createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    autocomplete,
    did_you_mean,
    header,
    result,
    report,
    advanced_result,
    inter,
    users,
    essais_clinique,
    detail,
    hta_roadmap,
    agency,
    translation
  }
});
